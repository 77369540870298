<ng-container *ngIf="ngControl.dirty && ngControl.touched && ngControl.errors">
  <small *ngIf="ngControl.hasError('required')" i18n>
    This field is required
  </small>

  <small *ngIf="ngControl.hasError('minlength')">
    Dieses Feld muss mindestens {{ ngControl.getError('minlength').requiredLength }} Zeichen enthalten
  </small>

  <small *ngIf="ngControl.hasError('maxlength')" i18n>
    Diese Feld darf maximal {{ ngControl.getError('maxlength').requiredLength }} Zeichen enthalten
  </small>

  <small *ngIf="ngControl.hasError('email')" i18n>
    This field must be a valid email address
  </small>

  <small *ngIf="ngControl.hasError('min')">
    Der Wert muss größer als {{ ngControl.getError('min').min }} sein
  </small>

  <small *ngIf="ngControl.hasError('max')">
    Der Wert muss kleiner als {{ ngControl.getError('max').max }} sein
  </small>

  <small *ngIf="ngControl.hasError('passwordMatch')" i18n>
    Both passwords must match
  </small>
</ng-container>
