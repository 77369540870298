import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./pages/login/login.component";

const routes: Routes = [
  {
    'path': 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
      description: $localize `Login to your account`
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
