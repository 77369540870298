import { Component } from '@angular/core';
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-form-error',
  standalone: true,
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  imports: [
    CommonModule
  ]
})
export class FormErrorComponent implements ControlValueAccessor {

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
  }

}
